.dev-hide {
  display: none;
}
.p-questions {
  font-size: 14px;
  padding-bottom: 40px;
  &-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
  }
  &-col {
    padding: 0 15px;
    width: 50%;
    margin-bottom: 30px;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  &-item {
    padding: 20px 30px;
    background-color: #f4f3f3;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  &-header {
    margin-bottom: 20px;
    &-title {
      &-colored {
        color: #36abdf;
      }
    }
  }
  &-body {
    &-title {
      &-colored {
        color: #e41f42;
      }
    }
  }
  &-block {
    &-title {
      align-items: baseline;
      @media (max-width: 414px) {
        flex-wrap: wrap;
      }
    }
  }
  &-button {
    &-modal {
      cursor: pointer;
      color: #36abdf;
      font-size: 12px;
      margin-left: 30px;
      font-weight: bold;
      text-decoration: none;
      transition: color .4s;
      &:hover {
        color: #e6193d;
        text-decoration: none;
      }
      @media (max-width: 414px) {
        width: 100%;
        margin-left: 0;
        margin-top: 20px;
        padding: 10px;
        border: 1px solid #36abdf;
        text-align: center;
        border-radius: 5px;
        text-transform: uppercase;
      }
    }
  }
  .catalog-in-text-message {
    max-width: none;
  }
}

.header {
  &-row {
    @media (max-width: 1200px) {
      display: flex;
    }
    @media (max-width: 992px) {
      display: block;
    }
  }
  &-col {
    &--search {
      @media (max-width: 992px) {
        display: none;
      }
    }    
  }

  &-contacts {
    &__label {
      font-size: 12px;
    }
    &__phone {
      margin-bottom: 2px;
      white-space: nowrap;
    }
    &-link {
      color: #2caae1;
    }
  }
}

.p {
  &-row {
    display: flex;
    margin: 0 -7px;
    flex-wrap: wrap;
  }
  &-col {
    width: 50%;
    padding: 0 7px;
    @media (max-width: 600px) {
      width: 100%;
    }
    &-connection {
      margin-bottom: 30px;
    }
  }
  &-info {
    &-colored {
      font-weight: bold;
    }
    &-item {
      display: flex;
      font-size: 12px;
      margin-bottom: 10px;
      &-icon {
        flex-shrink: 0;
        margin-right: 5px;
      }
      &-text {

      }
      &-link {
        font-weight: bold;
        color: #2daae1;
        text-decoration: none;
      }
    }
  }
  &-delivery {
    display: flex;
    flex-direction: column;
    &-link {
      color: #000;
      text-decoration: underline;
      margin-bottom: 3px;
      line-height: 1.3;
    }
  }
}

.search {
  &-group {
    &__field {
      font-size: 14px;
      padding: 0 100px 0 15px;
      @media (min-width: 993px) and (max-width: 1200px) {
        padding: 0 15px;
      }
      @media (max-width: 992px) {
        padding: 0 150px 0 30px;
      }
      @media (max-width: 600px) {
        padding: 0 30px;
      }
    }
    &-example {
      @media (min-width: 993px) and (max-width: 1200px) {
        display: none;
      }
      &__item {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 150px;
      }
    }
  }
}


.q-modal {
  max-width: 700px;
  box-sizing: border-box;
  width: 100%;
  padding: 0;
  text-align: left;
  border-radius: 15px;
  overflow: hidden;
  background-color: transparent;
  transform: translate3d(0,0,0);
  margin: 0 10px;
  .modal-title {
    @media (max-width: 600px) {
      font-size: 22px;
    }
  }
  .modal-product {
    @media (max-width: 600px) {
      padding: 12px 20px;
    }
  }
  .modal-header {
    min-height: 64px;
    @media (max-width: 600px) {
      padding: 20px;
    }
  }
  .form-group {
    display: flex;
    align-items: center;
    @media (max-width: 600px) {
      align-items: baseline;
      flex-direction: column;
    }
    label {
      margin: 0;
      width: 120px;
      flex-shrink: 0;
      font-size: 12px;
      line-height: 1.2;
      padding-right: 15px;
      @media (max-width: 600px) {
        margin-bottom: 5px;
      }
    }
  }
  &-wrapper {    
    vertical-align: middle;
    position: fixed;
    z-index: 10000;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(43,46,56,.9);
  }
  &-body {
    position: relative;
    @media (max-width: 600px) {
      padding: 20px;
    }
    &--submitted {
      color: #fff;
      background-color: #e6193d;
    }
  }
  &-loading {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(#fff, .6);
  }
}

.form-control {
  &--error {
    .p-questions .form-group & {
      border-color: red;  
    }
  }
}

.hide {
  display: none;
}

.message {
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem;
  &--danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }
  &-list {
    margin: 0;
    padding-left: 20px;
  }
}
